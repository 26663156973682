import React, { Fragment, useEffect } from 'react';
import classnames from 'classnames';
import { Link, graphql } from 'gatsby';
import get from 'lodash/get';
import Img from 'gatsby-image';
import css from './Category.module.css';
import setTheme from '@utils/setTheme';
import ScrollBar from 'react-overlayscrollbars';
import { DefaultMainMenu } from '@components';
import { isOffline } from '@utils';

const Category = ({ data }) => {
  const children = get(data, 'children.nodes') || [];
  const placeholderImage = get(data, 'placeholder.childImageSharp.fluid');

  useEffect(() => {
    setTheme('light');
  }, []);

  return (
    <Fragment>
      <DefaultMainMenu />
      <ScrollBar 
        className={classnames('os-theme-light', css.scrollContainer)}
        id="category-frontpage-scrollbar"
        // overflow-behavior={{ y: 'hidden' }}
        scrollbars={{ autoHide: 'l' }}
      >
        <ul className={css.list}>
          {children.map(child => {
              const title = get(child, 'options.categoryTitle') || child.title;
              const image = get(child, 'options.categoryImage.localFile.childImageSharp.fluid', null);

              return (
                <li
                  className={css.list__item}
                  key={`product-${child.uri}`}
                >
                  <Link to={child.uri} className={css.list__item__link}>
                    <div className={css.list__item__image}>
                      <svg viewBox="0 0 352 353" className={css.list__item__circle}>
                        <path
                          d="M290.158438,43.4215491 C259.407164,16.9246568 219.371878,0.905 175.595,0.905 C78.6165594,0.905 0,79.5215594 0,176.5 C0,273.478441 78.6165594,352.095 175.595,352.095 C272.573441,352.095 351.19,273.478441 351.19,176.5 C351.19,158.839461 348.582817,141.787862 343.732346,125.709099 L342.87774,126.340805 C347.634536,142.227588 350.19,159.065364 350.19,176.5 C350.19,272.926156 272.021156,351.095 175.595,351.095 C79.1688441,351.095 1,272.926156 1,176.5 C1,80.0738441 79.1688441,1.905 175.595,1.905 C219.040882,1.905 258.780432,17.7736952 289.33376,44.0311972 L290.158438,43.4215491 Z"
                          fill="#000"
                        />
                      </svg>
                      <Img
                        loading={isOffline ? 'eager' : 'lazy'}
                        fluid={image || placeholderImage}
                        className={css.list__item__image__img}
                        alt={title}
                      />
                    </div>
                    <div className={css.content}>
                      <div className={css.content__title} dangerouslySetInnerHTML={{ __html: title }} />
                      <div className={css.content__goto}>
                        <span className={css.content__goto__label}>Go to page</span>
                        <svg viewBox="0 0 17 13" className={css.content__goto__icon}>
                          <path
                            d="M11.4476024,0.854471502 C11.2160051,0.613842833 10.8305494,0.613842833 10.5908542,0.854471502 C10.3592569,1.08697082 10.3592569,1.47392774 10.5908542,1.70588511 L14.9350587,6.06700876 L0.599777707,6.06700876 C0.26560813,6.06755072 0,6.3341933 0,6.66966435 C0,7.0051354 0.26560813,7.28044928 0.599777707,7.28044928 L14.9350587,7.28044928 L10.5908542,11.6334436 C10.3592569,11.8740723 10.3592569,12.2615711 10.5908542,12.4935285 C10.8305494,12.7341572 11.2165449,12.7341572 11.4476024,12.4935285 L16.8202286,7.09997778 C17.0599238,6.86747846 17.0599238,6.48052154 16.8202286,6.24856418 L11.4476024,0.854471502 Z"
                            fill="#000"
                          />
                        </svg>
                      </div>
                    </div>
                  </Link>
                </li>
              )
            })}
        </ul>
      </ScrollBar>
    </Fragment>
  );
}

export default Category;

export const categoryPageQuery = graphql`
  fragment CategoryPlaceholder on File  {
    childImageSharp {
      fluid(maxWidth: 1000, srcSetBreakpoints: [500]) {
        ...GatsbyImageSharpFluid_noBase64
      }
    }
  }
  query($id: String!) {
    page: wpPage(id: { eq: $id }) {
      template {
        __typename
      }
      databaseId
      uri
      title
      ...DefaultPageFields
    }
    placeholder: file(name: { eq: "category-image-placeholder" }) {
     ...CategoryPlaceholder
    }
    children: allWpPage(
      filter: { wpParent: { node: { id: { eq: $id } } }, options: { categoryShow: { eq: true }} }
      sort: { fields: menuOrder, order: ASC }
    ) {
      nodes {
        title
        uri
        options {
          categoryTitle
          categoryImage {
            localFile {
              publicURL
              ...CategoryPlaceholder
            }
          }
        }
      }
    }
  }
`;